import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { Group } from '../group.reducer';
import { GroupService } from '../group.service';
import { setGroup } from './base.actions';

export const updateGroupAction = createAsyncThunk<void, Group, ThunkConfig>(
  'group/update-group-update-action',
  async (group, { getState, rejectWithValue, dispatch }) => {
    dispatch(setGroup(group));
    const token = getState().app.token;

    if (!token) {
      return rejectWithValue(null);
    }

    await GroupService.updateGroup(token, group);
  }
);
