import { createReducer } from '@reduxjs/toolkit';
import { Group, Participant } from '../group/group.reducer';
import { loadDrawsAction } from './actions/load-draws.action';
import { createDrawAction } from './actions/create-draws.action';
import { updateDrawStatusAction } from './actions/update-draw-status.action';

export enum DrawFetchState {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Loaded = 'LOADED',
}

export enum DrawingOperationState {
  Idle = 'IDLE',
  Drawing = 'DRAWING',
  Drew = 'DREW',
}

export interface DrawTeam {
  name: string;
  participants: Participant[];
}

export enum DrawResult {
  Draw = 'DRAW',
  NotPlayed = 'NOT_PLAYED',
  Pending = 'PENDING',
  Win = 'WIN',
}

export type DrawStatus =
  | { result: DrawResult; value?: undefined }
  | { result: DrawResult.Win; value: DrawTeam['name'] };

export interface Draw {
  id: string;
  groupId: string;
  createdAt: Date;
  teams: DrawTeam[];
  drawStatus: DrawStatus;
}

interface DrawState {
  draws: Record<Group['id'], Draw[]>;
  drawsFetchState: Record<Group['id'], DrawFetchState>;
  drawingState: DrawingOperationState;
}

const initialState: DrawState = {
  draws: {},
  drawsFetchState: {},
  drawingState: DrawingOperationState.Idle,
};

export const draw = createReducer(initialState, (builder) => {
  builder.addCase(loadDrawsAction.pending, (state, action) => {
    const groupId = action.meta.arg;
    state.drawsFetchState[groupId] = DrawFetchState.Loading;
  });
  builder.addCase(loadDrawsAction.fulfilled, (state, action) => {
    const groupId = action.meta.arg;
    state.draws[groupId] = action.payload;
    state.drawsFetchState[groupId] = DrawFetchState.Loaded;
  });
  builder.addCase(createDrawAction.pending, (state) => {
    state.drawingState = DrawingOperationState.Drawing;
  });
  builder.addCase(createDrawAction.fulfilled, (state, action) => {
    const groupId = action.meta.arg.groupId;
    state.drawingState = DrawingOperationState.Idle;
    const draws = state.draws[groupId] ?? [];
    state.draws[groupId] = [...draws, action.payload];
  });
  builder.addCase(updateDrawStatusAction.fulfilled, (state, action) => {
    const groupId = action.payload.groupId;
    const draws = state.draws[groupId] ?? [];
    state.draws[groupId] = draws.map((draw) => {
      if (draw.id === action.payload.id) {
        return action.payload;
      }
      return draw;
    });
  });
});
