import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { setToken } from './base.action';
import { LocalStorageService } from '../../utils/local-storage.service';
import { Params } from '../../utils/use-query-params-with-thunk.hook';
import { loadGroupsAction } from '../../group/actions/load-groups.action';
import { AuthService } from '../auth.service';

const validateToken = async (
  token: string | null
): Promise<string | undefined> => {
  if (token) {
    const isValid = await AuthService.validateToken(token);
    if (isValid) {
      return token;
    }
  }
  return undefined;
};

export const initializeAppAction = createAsyncThunk<
  Params,
  Params,
  ThunkConfig
>('app/initialize-app', async (params, { dispatch }) => {
  const paramsToken = params['token'];
  const storageToken = LocalStorageService.getItem('token');

  const paramsTokenValidated = await validateToken(paramsToken);
  if (paramsTokenValidated) {
    LocalStorageService.setItem('token', paramsToken);
    dispatch(setToken(paramsToken));
    await dispatch(loadGroupsAction(paramsToken));
    return { ...params, token: paramsToken };
  }

  const storageTokenValidated = await validateToken(storageToken);
  if (storageTokenValidated && storageToken !== null) {
    LocalStorageService.setItem('token', storageToken);
    dispatch(setToken(storageToken));
    await dispatch(loadGroupsAction(storageToken));
    return { ...params, token: storageToken };
  }

  LocalStorageService.removeItem('token');

  return params;
});
