import { apiClientService } from '../utils/api-client.service';

// const API_BASE_URL = process.env.API_BASE_URL;
const API_BASE_URL = '/roster-api/v1';

export class AuthService {
  static async validateToken(token: string): Promise<boolean> {
    console.log('API_BASE_URL', API_BASE_URL);
    try {
      const response = await apiClientService.get(`${API_BASE_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.status === 204;
    } catch (error) {
      console.error('Token validation failed:', error);
      return false;
    }
  }

  static async createUser(): Promise<string | undefined> {
    try {
      const response = await apiClientService.post(`${API_BASE_URL}/users`);
      const token = response.data.token;

      if (token) {
        return token;
      }

      return undefined;
    } catch (error) {
      console.error('Error creating user:', error);
      return undefined;
    }
  }
}
