import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AccessLevel, Group } from './group.reducer';

const selectGroupState = (state: RootState) => state.group;

export const selectGroups = createSelector(selectGroupState, (groupState) =>
  Object.values(groupState.groups)
);

export const selectGroup = createSelector(
  [selectGroupState, (_, id: string) => id],
  (groupState, id): Group | undefined => groupState.groups[id]
);

export const selectGroupAccessLevel = createSelector(
  selectGroup,
  (group): AccessLevel => group?.accessLevel ?? AccessLevel.Read
);
