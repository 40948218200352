import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useAppDispatch } from './use-app-dispatch.hook';

interface useQueryParamsWithThunkOptions {
  update?: boolean;
}

export type Params = { [p: string]: string };

export const useQueryParamsWithThunk = (
  thunk: AsyncThunk<Params, Params, any>,
  options: useQueryParamsWithThunkOptions = {}
) => {
  const { update = true } = options;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());

    const handler = async () => {
      const { payload } = await dispatch(thunk(params));

      const updatedParams = { ...params, ...(payload as Params) };

      update && setSearchParams(updatedParams);
    };
    void handler();
  }, [searchParams, dispatch, thunk, update, setSearchParams]);
};
