import axios from 'axios';

const VIRTUAL_HOST: string = "pickroster.com"
// const VIRTUAL_HOST: string = 'localhost';

export const apiClientService = axios.create({
  baseURL:
    VIRTUAL_HOST === 'localhost'
      ? 'http://localhost:9001'
      : `https://${VIRTUAL_HOST}`,
});
