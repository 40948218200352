import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useAppSelector } from '../../../utils/use-app-selector.hook';
import { useAppDispatch } from '../../../utils/use-app-dispatch.hook';
import { selectGroup } from '../../../group/group.selector';
import { createDrawAction } from '../../actions/create-draws.action';
import { hasAtLeastNSelected } from '../../../utils/has-at-least-n-selected.helper';
import { Counter } from '../../../ui/counter.component';
import { generateGroupTeamsWithDefaults } from './generate-group-teams-with-defaults';
import { loadGroupAction } from '../../../group/actions/load-group.action';

export const DrawCreate = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const group = useAppSelector((state) => selectGroup(state, id));

  const [allSelected, setAllSelected] = useState(false);
  const [teamCount, setTeamCount] = useState(2);
  const [selectedParticipantIds, setSelectedParticipantIds] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (group?.accessLevel === 'readonly') {
      navigate(`/draw/${id}`);
    }
  }, [navigate, group, id]);

  useEffect(() => {
    if (group === undefined) {
      void dispatch(loadGroupAction(id));
    }
  }, [dispatch, group, id]);

  if (!group || group?.accessLevel === 'readonly') {
    return <p>Group Not Found: Create new one to the Home</p>;
  }

  const handleDrawClick = () => {
    const participants =
      group?.participants.filter(
        (participant) => selectedParticipantIds[participant.id]
      ) ?? [];

    const groupTeams = generateGroupTeamsWithDefaults(teamCount, [
      ...group?.teams,
    ]);

    dispatch(createDrawAction({ groupId: id, groupTeams, participants }));
    navigate(`/draw/${id}`);
  };
  const handleGroupUpdateClick = () => {
    navigate(`/group-update/${id}`);
  };

  const handleDrawHistoryClick = () => {
    navigate(`/draw-history/${id}`);
  };

  const handleGroupStatsClick = () => {
    navigate(`/group-stats/${id}`);
  };

  return (
    <div>
      <h2>🎲 Wylosuj Składy</h2>
      <h3>Grupa: {group.name}</h3>
      <div style={{ display: 'flex' }}>
        <label>Ilość Składów:</label>
        <Counter initValue={2} min={2} max={4} onChange={setTeamCount} />
      </div>
      <ParticipantListItem>
        <input
          type={'checkbox'}
          checked={allSelected}
          onChange={(e) => {
            setAllSelected(e.target.checked);
            setSelectedParticipantIds(
              group.participants.reduce(
                (acc, participant) => ({
                  ...acc,
                  [participant.id]: e.target.checked,
                }),
                {}
              )
            );
          }}
        />
        <p style={{ padding: '0 0 0 0.3rem' }}>
          {allSelected ? 'Odznacz wszystkich' : 'Wybierz wszystkich'}
        </p>
      </ParticipantListItem>
      <ParticipantList>
        {group.participants.map((participant) => (
          <ParticipantListItem key={participant.id}>
            <input
              type={'checkbox'}
              checked={selectedParticipantIds[participant.id]}
              onChange={(e) =>
                setSelectedParticipantIds((prevState) => ({
                  ...prevState,
                  [participant.id]: e.target.checked,
                }))
              }
            />
            <div style={{ padding: '0 0 0 0.3rem' }}>{participant.name}</div>
          </ParticipantListItem>
        ))}
      </ParticipantList>
      <ActionList>
        <button
          disabled={!hasAtLeastNSelected(selectedParticipantIds, teamCount)}
          onClick={handleDrawClick}
        >
          Losuj Składy
        </button>
        <button onClick={handleDrawHistoryClick}>Historia Losowań</button>
        <button onClick={handleGroupStatsClick}>Statystyki Grupy</button>
        <button onClick={handleGroupUpdateClick}>Zarządzaj Grupą</button>
      </ActionList>
    </div>
  );
};

const ParticipantList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ParticipantListItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionList = styled.div`
  display: flex;
  flex-direction: column;
`;
