import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { FC } from '../../utils/fc.type';
import { InitializeApp } from './initialize-app.component';
import { useAppSelector } from '../../utils/use-app-selector.hook';
import { InitializedState } from '../app.reducer';

export const AppLayout: FC = ({ children }) => {
  const initialized = useAppSelector(
    (state) => state.app.initialized === InitializedState.Initialized
  );
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <Layout>
      <Navigation>
        <h1 onClick={handleHomeClick}>Roster App</h1>
        <h4>Zarządzaj grupami i twórz losowe składy</h4>
      </Navigation>
      <View>
        <>{!initialized ? <InitializeApp /> : <>{children}</>}</>
      </View>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 5.2rem calc(100vh - 5.2rem);
  grid-template-areas:
    'Navigation'
    'View';
  margin: 0 auto;
`;

const Navigation = styled.div`
  grid-area: Navigation;
  border-bottom: 1px solid black;
  padding: 0 1.2rem;
`;

const View = styled.div`
  grid-area: View;
  padding: 0.6rem 1.2rem;
`;
