import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { app } from './app/app.reducer';
import { group } from './group/group.reducer';
import { draw } from './draw/draw.reducer';

const reducer = combineReducers({
  app,
  group,
  draw,
});

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};
