import React, { useState } from 'react';
import { FC } from '../utils/fc.type';

interface CounterProps {
  initValue?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
}

export const Counter: FC<CounterProps> = ({
  initValue = 0,
  min = -Infinity,
  max = Infinity,
  onChange,
}) => {
  const [count, setCount] = useState(initValue);

  const increment = () => {
    if (count < max) {
      const newValue = count + 1;
      setCount(newValue);
      onChange?.(newValue);
    }
  };

  const decrement = () => {
    if (count > min) {
      const newValue = count - 1;
      setCount(newValue);
      onChange?.(newValue);
    }
  };

  return (
    <div>
      <label style={{ padding: '0 9px 0 3px' }}>{count}</label>
      <button
        style={{ width: '40px' }}
        onClick={increment}
        disabled={count >= max}
      >
        +
      </button>
      <button
        style={{ width: '40px' }}
        onClick={decrement}
        disabled={count <= min}
      >
        -
      </button>
    </div>
  );
};
