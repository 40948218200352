import { Modal } from '../../../ui/modal.component';
import { FC } from '../../../utils/fc.type';
import { Draw, DrawResult, DrawStatus } from '../../draw.reducer';

export const DrawSummaryModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  onDrawStatusClick: (status: DrawStatus) => void;
  teams: Draw['teams'];
}> = ({ onDrawStatusClick, teams, ...props }) => {
  return (
    <Modal {...props}>
      <h1>Z jakim rezultatem zakończyło się losowanie</h1>
      {teams.map((team) => {
        const status: DrawStatus = { result: DrawResult.Win, value: team.name };
        return (
          <p key={team.name} onClick={() => onDrawStatusClick(status)}>
            Wygrana {team.name}
          </p>
        );
      })}
      <p onClick={() => onDrawStatusClick({ result: DrawResult.Draw })}>
        Remis
      </p>
      <p onClick={() => onDrawStatusClick({ result: DrawResult.NotPlayed })}>
        Losowanie nie rozegrane
      </p>
    </Modal>
  );
};
