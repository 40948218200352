import { GroupTeam } from '../../../group/group.reducer';

export function generateGroupTeamsWithDefaults(
  defaultCount: number,
  existingTeams: GroupTeam[] = []
): GroupTeam[] {
  const startIndex = existingTeams.length + 1;

  const additionalTeams = Array.from(
    { length: defaultCount - existingTeams.length },
    (_, index) => ({
      name: `Drużyna Nr ${startIndex + index}`,
    })
  );

  return [...existingTeams, ...additionalTeams];
}
