import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useAppSelector } from '../../../utils/use-app-selector.hook';
import { useAppDispatch } from '../../../utils/use-app-dispatch.hook';
import { formatDate } from '../../../utils/format-date';
import { selectGroupAccessLevel } from '../../../group/group.selector';
import { AccessLevel } from '../../../group/group.reducer';
import {
  selectDrawsFetchStateByGroupId,
  selectLastDrawByGroupId,
} from '../../draw.selector';
import {
  DrawFetchState,
  DrawingOperationState,
  DrawResult,
  DrawStatus,
} from '../../draw.reducer';
import { loadDrawsAction } from '../../actions/load-draws.action';
import { updateDrawStatusAction } from '../../actions/update-draw-status.action';
import { DrawSummaryModal } from './draw-summary-modal.component';

export const Draw = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [drawSummaryOpen, setDrawSummaryOpen] = useState(false);
  const drawsFetchState = useAppSelector((state) =>
    selectDrawsFetchStateByGroupId(state, id)
  );
  const accessLevel = useAppSelector((state) =>
    selectGroupAccessLevel(state, id)
  );
  const drawingState = useAppSelector((state) => state.draw.drawingState);
  const draw = useAppSelector((state) => selectLastDrawByGroupId(state, id));

  const lastDrawFinished =
    !draw ||
    (draw.drawStatus.result !== DrawResult.Pending && accessLevel === 'owner');

  useEffect(() => {
    if (
      lastDrawFinished &&
      drawingState === DrawingOperationState.Idle &&
      drawsFetchState === DrawFetchState.Loaded &&
      accessLevel === 'owner'
    ) {
      navigate(`/draw-create/${id}`);
    }
  }, [
    accessLevel,
    drawsFetchState,
    drawingState,
    lastDrawFinished,
    navigate,
    id,
  ]);

  useEffect(() => {
    if (drawsFetchState === DrawFetchState.Idle) {
      dispatch(loadDrawsAction(id));
    }
  }, [dispatch, drawsFetchState, id]);

  if (drawsFetchState !== DrawFetchState.Loaded) {
    return (
      <div>
        <h2>Ładowanie danych...</h2>
      </div>
    );
  }

  if (drawingState === DrawingOperationState.Drawing) {
    return (
      <div>
        <h2>Losowanie...</h2>
      </div>
    );
  }

  if (lastDrawFinished) {
    return null;
  }

  const handleNewDrawClick = () => {
    setDrawSummaryOpen(true);
  };

  const handleDrawHistoryClick = () => {
    navigate(`/draw-history/${id}`);
  };

  const handleGroupStatsClick = () => {
    navigate(`/group-stats/${id}`);
  };

  const handleGroupUpdateClick = () => {
    navigate(`/group-update/${id}`);
  };

  const formattedDate = formatDate(draw.createdAt);

  const handleDrawSummaryClose = () => {
    setDrawSummaryOpen(false);
  };

  const handleDrawStatusClick = (drawStatus: DrawStatus) => {
    dispatch(updateDrawStatusAction({ ...draw, drawStatus }));
    navigate(`/draw-create/${id}`);
  };

  return (
    <>
      <div>
        <h2>📋 Składy</h2>
        <h3>{formattedDate}</h3>
        <div>
          {draw.teams.map(({ name, participants }, index) => (
            <div key={name}>
              <h4>{name}:</h4>
              <ul>
                {participants.map((participant) => (
                  <li key={participant.id}>{participant.name}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <ActionList>
          {accessLevel === AccessLevel.Owner && (
            <button onClick={handleNewDrawClick}>Losuj Nowe Składy</button>
          )}
          <button onClick={handleDrawHistoryClick}>Historia Losowań</button>
          <button onClick={handleGroupStatsClick}>Statystyki Grupy</button>
          {accessLevel === AccessLevel.Owner && (
            <button onClick={handleGroupUpdateClick}>Zarządzaj Grupą</button>
          )}
        </ActionList>
      </div>
      <DrawSummaryModal
        isOpen={drawSummaryOpen}
        onClose={handleDrawSummaryClose}
        onDrawStatusClick={handleDrawStatusClick}
        teams={draw.teams}
      />
    </>
  );
};
const ActionList = styled.div`
  display: flex;
  flex-direction: column;
`;
