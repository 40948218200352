import { apiClientService } from '../utils/api-client.service';
import { Group } from './group.reducer';

const API_BASE_URL = '/roster-api/v1';

export class GroupService {
  static async getGroup(id: string, token = ''): Promise<Group> {
    try {
      const result = await apiClientService.get<Group>(
        `${API_BASE_URL}/groups/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result.data;
    } catch (error) {
      console.error('Error fetching groups:', error);
      throw new Error('Failed to fetch groups');
    }
  }

  static async getGroups(token: string): Promise<Record<Group['id'], Group>> {
    try {
      const result = await apiClientService.get<Group[]>(
        `${API_BASE_URL}/groups`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result.data.reduce(
        (prev, group) => {
          prev[group.id] = group;

          return prev;
        },
        {} as Record<Group['id'], Group>
      );
    } catch (error) {
      console.error('Error fetching groups:', error);
      throw new Error('Failed to fetch groups');
    }
  }

  static async createGroup(token: string, group: Group): Promise<Group> {
    try {
      const result = await apiClientService.post(
        `${API_BASE_URL}/groups`,
        group,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result.data;
    } catch (error) {
      console.error('Error creating group:', error);
      throw new Error('Failed to create group');
    }
  }

  static async updateGroup(
    token: string,
    group: Partial<Group>
  ): Promise<Group> {
    try {
      const result = await apiClientService.put(
        `${API_BASE_URL}/groups/${group.id}`,
        group,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result.data;
    } catch (error) {
      console.error('Error updating group:', error);
      throw new Error('Failed to update group');
    }
  }
}
