import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import ParticipantTable from './group-participant-table.component';
import { useAppSelector } from '../../../utils/use-app-selector.hook';
import { selectGroup } from '../../group.selector';
import { FC } from '../../../utils/fc.type';
import { useAppDispatch } from '../../../utils/use-app-dispatch.hook';
import { loadGroupAction } from '../../actions/load-group.action';

export const GroupStats: FC = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const group = useAppSelector((state) => selectGroup(state, id));

  const handleBackButtonClick = () => {
    navigate(`/draw/${id}`);
  };

  useEffect(() => {
    if (group === undefined) {
      void dispatch(loadGroupAction(id));
    }
  }, [dispatch, group, id]);

  return (
    <>
      {!group ? (
        <div>
          <h2>Ładowanie danych...</h2>
        </div>
      ) : (
        <div>
          <h2>📊 Statystyki Grupy</h2>
          <ParticipantTable participants={group.participants} />
          <ActionList>
            <button onClick={handleBackButtonClick}>Wróć</button>
          </ActionList>
        </div>
      )}
    </>
  );
};

const ActionList = styled.div`
  display: flex;
  flex-direction: column;
`;
