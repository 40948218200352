import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { Draw } from '../draw.reducer';
import { DrawService } from '../draw.service';
import { loadGroupAction } from '../../group/actions/load-group.action';

export const updateDrawStatusAction = createAsyncThunk<Draw, Draw, ThunkConfig>(
  'draw/update-draw-status-action',
  async (draw, { getState, rejectWithValue, dispatch }) => {
    const token = getState().app.token;

    if (!token) {
      return rejectWithValue(null);
    }

    const { id, groupId, drawStatus } = draw;

    const result = await DrawService.updateDrawStatus(token, groupId, id, {
      drawStatus,
    });

    await dispatch(loadGroupAction(groupId));

    return result;
  }
);
