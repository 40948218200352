import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { InputWithButton } from '../../../ui/input-with-button';
import { useAppSelector } from '../../../utils/use-app-selector.hook';
import { selectGroup } from '../../group.selector';
import { generateId } from '../../../utils/generate-id.helper';
import { useAppDispatch } from '../../../utils/use-app-dispatch.hook';
import { updateGroupAction } from '../../actions/update-group.action';
import { GroupTeam } from '../../group.reducer';
import { defaultGroupTeams } from '../../group.constans';

export const GroupUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const group = useAppSelector((state) => selectGroup(state, id));

  const [name, setName] = useState(group?.name ?? '');
  const [teams, setTeams] = useState<GroupTeam[]>(
    group?.teams ?? defaultGroupTeams
  );
  const [participants, setParticipants] = useState(group?.participants ?? []);

  const handleAddParticipantClick = (name: string) => {
    const id = generateId();
    setParticipants((prevState) => [{ id, name }, ...prevState]);
  };

  const handleParticipantNameChange = (id: string, name: string) => {
    setParticipants((prevState) =>
      prevState.map((participant) =>
        participant.id === id ? { ...participant, name } : participant
      )
    );
  };
  const handleParticipantDeleteClick = (id: string) => {
    setParticipants((prevState) =>
      prevState.filter((participant) => participant.id !== id)
    );
  };

  const handleGroupUpdateClick = () => {
    dispatch(
      updateGroupAction({
        id,
        name,
        teams,
        participants,
      })
    );
    navigate(`/draw/${id}`);
  };

  const handleCancelUpdateClick = () => {
    navigate(-1);
  };

  return (
    <>
      {!group ? (
        <p>Group Not Found: Create new one to the Home</p>
      ) : (
        <div>
          <h2>✏️ Edycja Grupy</h2>
          <h5>Zmień nazwę grupy:</h5>
          <input
            placeholder={'Wpisz nazwę grupy'}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <h5>Zarządzaj domyślnymi nazwami składów:</h5>
          <h5>Zmień nazwę Składu 1:</h5>
          <input
            placeholder={'Wpisz nazwę składu 1'}
            type="text"
            value={teams[0].name}
            onChange={(e) =>
              setTeams((prevState) => {
                return prevState.map((team, index) => {
                  if (index === 0) {
                    return { ...team, name: e.target.value };
                  }
                  return team;
                });
              })
            }
          />
          <h5>Zmień nazwę Składu 2:</h5>
          <input
            placeholder={'Wpisz nazwę składu 2'}
            type="text"
            value={teams[1].name}
            onChange={(e) =>
              setTeams((prevState) => {
                return prevState.map((team, index) => {
                  if (index === 1) {
                    return { ...team, name: e.target.value };
                  }
                  return team;
                });
              })
            }
          />
          <h5>Zarządzaj listą uczestników:</h5>
          <InputWithButton
            buttonWidth={'40px'}
            buttonText={'➕'}
            placeholder={'Wpisz nazwę uczestnika'}
            onButtonClick={handleAddParticipantClick}
          />
          <ParticipantList>
            {participants.map((participant) => (
              <ParticipantListItem key={participant.id}>
                <input
                  placeholder={'Wpisz nazwę uczestnika'}
                  type="text"
                  value={participant.name}
                  onChange={(e) =>
                    handleParticipantNameChange(participant.id, e.target.value)
                  }
                />
                <button
                  style={{ width: '40px' }}
                  onClick={() => handleParticipantDeleteClick(participant.id)}
                >
                  🗑
                </button>
              </ParticipantListItem>
            ))}
          </ParticipantList>
          <ActionList>
            <button onClick={handleGroupUpdateClick}>Aktualizuj</button>
            <button onClick={handleCancelUpdateClick}>Anuluj</button>
          </ActionList>
        </div>
      )}
    </>
  );
};

const ParticipantList = styled.div`
  display: flex;
  flex-direction: column;
`;
const ParticipantListItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionList = styled.div`
  display: flex;
  flex-direction: column;
`;
