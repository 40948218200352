import React from 'react';
import { HashRouter, Route, Routes } from 'react-router';
import { AppLayout } from './components/app-layout.component';
import { Draw } from '../draw/components/draw/draw.component';
import { GroupUpdate } from '../group/components/group-update/group-update.component';
import { GroupList } from '../group/components/group-list/group-list.component';
import { DrawCreate } from '../draw/components/draw-create/draw-create.component';
import { DrawHistory } from '../draw/components/draw-history/draw-history.component';
import { GroupStats } from '../group/components/group-stats/group-stats';

function App() {
  return (
    <HashRouter>
      <AppLayout>
        <Routes>
          <Route path="/" element={<GroupList />} />
          <Route path="/draw/:id" element={<Draw />} />
          <Route path="/draw-create/:id" element={<DrawCreate />} />
          <Route path="/draw-history/:id" element={<DrawHistory />} />
          <Route path="/group-update/:id" element={<GroupUpdate />} />
          <Route path="/group-stats/:id" element={<GroupStats />} />
        </Routes>
      </AppLayout>
    </HashRouter>
  );
}

export default App;
