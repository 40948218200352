import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../utils/use-app-selector.hook';
import { selectGroups } from '../../group.selector';
import { GroupListItem } from './group-list-item.component';
import { GroupCreate } from './group-create.component';

export const GroupList = () => {
  const [, setSearchParams] = useSearchParams();
  const token = useAppSelector((state) => state.app.token) ?? '';
  const groups = useAppSelector(selectGroups);

  useEffect(() => {
    setSearchParams({ token });
  }, [token]);

  return (
    <>
      <h2>👥 Twoje Grupy</h2>
      <ul>
        {groups.map((group) => (
          <GroupListItem key={group.id} {...group} />
        ))}
      </ul>
      <GroupCreate />
    </>
  );
};
