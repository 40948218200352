import React from 'react';
import styled from 'styled-components';
import { FC } from '../../../utils/fc.type';
import { Participant } from '../../group.reducer';

const Table = styled.table`
  width: 100%;
  max-width: 600px;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

interface Props {
  participants: Participant[];
}

function calculateWinRate(drawsWon = 0, drawsPlayed = 0): number {
  if (drawsPlayed === 0) return 0;
  const winRate = (drawsWon / drawsPlayed) * 100;
  return Math.round(winRate * 100) / 100;
}

function sortParticipants(participants: Participant[]): Participant[] {
  return [...participants].sort((a, b) => {
    const drawsWonB = b.drawsWon ?? 0;
    const drawsWonA = a.drawsWon ?? 0;
    if (drawsWonB !== drawsWonA) {
      return drawsWonB - drawsWonA;
    }

    const drawsPlayedB = b.drawsPlayed ?? 0;
    const drawsPlayedA = a.drawsPlayed ?? 0;
    return drawsPlayedB - drawsPlayedA;
  });
}

const GroupParticipantTable: FC<Props> = ({ participants }) => {
  return (
    <Table>
      <thead>
        <Tr>
          <Th>Nazwa</Th>
          <Th>Wygrane</Th>
          <Th>Rozegrane mecze</Th>
          <Th>Stosunek wygranych</Th>
        </Tr>
      </thead>
      <tbody>
        {sortParticipants(participants).map((participant) => (
          <Tr key={participant.id}>
            <Td style={{ width: '70%' }}>{participant.name}</Td>
            <Td style={{ width: '10%' }}>{participant.drawsWon}</Td>
            <Td style={{ width: '10%' }}>{participant.drawsPlayed}</Td>
            <Td style={{ width: '10%' }}>
              {calculateWinRate(participant.drawsWon, participant.drawsPlayed)}%
            </Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};

export default GroupParticipantTable;
