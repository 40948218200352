import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { Group } from '../group.reducer';
import { GroupService } from '../group.service';

export const loadGroupsAction = createAsyncThunk<
  Record<Group['id'], Group>,
  string,
  ThunkConfig
>('group/load-groups-action', async (token) => {
  return await GroupService.getGroups(token);
});
