import { createReducer } from '@reduxjs/toolkit';
import { loadGroupsAction } from './actions/load-groups.action';
import { setGroup } from './actions/base.actions';
import { loadGroupAction } from './actions/load-group.action';

export enum AccessLevel {
  Read = 'readonly',
  Owner = 'owner',
}

export interface Participant {
  id: string;
  name: string;
  drawsPlayed?: number;
  drawsWon?: number;
}

export interface GroupTeam {
  name: string;
}

export interface Group {
  id: string;
  name: string;
  participants: Participant[];
  teams: GroupTeam[];
  accessLevel?: AccessLevel;
}

interface GroupState {
  groups: Record<Group['id'], Group>;
}

const initialState: GroupState = {
  groups: {},
};

export const group = createReducer(initialState, (builder) => {
  builder.addCase(setGroup, (state, action) => {
    state.groups[action.payload.id] = {
      ...state.groups[action.payload.id],
      ...action.payload,
    };
  });
  builder.addCase(loadGroupsAction.fulfilled, (state, action) => {
    state.groups = action.payload;
  });
  builder.addCase(loadGroupAction.fulfilled, (state, action) => {
    state.groups[action.payload.id] = {
      ...state.groups[action.payload.id],
      ...action.payload,
    };
  });
});
