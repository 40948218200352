import { createReducer } from '@reduxjs/toolkit';
import { setToken } from './actions/base.action';
import { initializeAppAction } from './actions/initialize-app.action';

export enum InitializedState {
  Idle = 'IDLE',
  Initializing = 'INITIALIZING',
  Initialized = 'INITIALIZED',
}

interface AppState {
  token?: string;
  initialized: InitializedState;
}

const initialState: AppState = {
  token: undefined,
  initialized: InitializedState.Idle,
};

export const app = createReducer(initialState, (builder) => {
  builder.addCase(setToken, (state, action) => {
    state.token = action.payload;
  });
  builder.addCase(initializeAppAction.pending, (state, action) => {
    state.initialized = InitializedState.Initializing;
  });
  builder.addCase(initializeAppAction.fulfilled, (state, action) => {
    state.initialized = InitializedState.Initialized;
  });
});
