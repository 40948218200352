import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../utils/use-app-dispatch.hook';
import { createGroupAction } from '../../actions/create-group.action';
import { generateId } from '../../../utils/generate-id.helper';
import { InputWithButton } from '../../../ui/input-with-button';

export const GroupCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleButtonClick = (name: string) => {
    const id = generateId();
    dispatch(createGroupAction({ id, name }));
    navigate(`/group-update/${id}`);
  };

  return (
    <InputWithButton
      placeholder={'Wpisz nazwę grupy'}
      buttonText={'➕'}
      buttonWidth={'40px'}
      onButtonClick={handleButtonClick}
    />
  );
};
