import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { loadGroupAction } from '../../group/actions/load-group.action';
import { Draw } from '../draw.reducer';
import { DrawService } from '../draw.service';

export const loadDrawsAction = createAsyncThunk<Draw[], string, ThunkConfig>(
  'draw/load-draws-action',
  async (groupId, { dispatch }) => {
    await dispatch(loadGroupAction(groupId));
    return await DrawService.getDraws(groupId);
  }
);
