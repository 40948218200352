import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { GroupTeam, Participant } from '../../group/group.reducer';
import { Draw } from '../draw.reducer';
import { DrawService } from '../draw.service';

export const createDrawAction = createAsyncThunk<
  Draw,
  { groupId: string; participants: Participant[]; groupTeams: GroupTeam[] },
  ThunkConfig
>(
  'draw/create-draw-action',
  async (
    { participants, groupId, groupTeams },
    { getState, rejectWithValue }
  ) => {
    const teamNames = groupTeams.map((team) => team.name);
    const token = getState().app.token;

    if (!token) {
      return rejectWithValue(null);
    }

    return await DrawService.createDraw(
      token,
      groupId,
      teamNames,
      participants
    );
  }
);
