import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Draw } from './draw.reducer';

const selectDrawState = (state: RootState) => state.draw;

export const selectDrawsByGroupId = createSelector(
  [selectDrawState, (_, groupId: string) => groupId],
  (drawState, groupId) => {
    const draws: Draw[] = drawState.draws[groupId] ?? [];
    return [...draws].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }
);

export const selectFinishedDrawsByGroupId = createSelector(
  selectDrawsByGroupId,
  (draws) => draws.filter((draw) => draw.drawStatus.result !== 'PENDING')
);

export const selectDrawsFetchStateByGroupId = createSelector(
  [selectDrawState, (_, groupId: string) => groupId],
  (drawState, groupId) => drawState.drawsFetchState[groupId] ?? 'IDLE'
);

export const selectLastDrawByGroupId = createSelector(
  selectDrawsByGroupId,
  (draws) => (draws.length > 0 ? draws[0] : undefined)
);
