import React, { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useAppSelector } from '../../../utils/use-app-selector.hook';
import {
  selectDrawsFetchStateByGroupId,
  selectFinishedDrawsByGroupId,
} from '../../draw.selector';
import { formatDate } from '../../../utils/format-date';
import { Draw, DrawFetchState, DrawResult } from '../../draw.reducer';
import { loadDrawsAction } from '../../actions/load-draws.action';
import { useAppDispatch } from '../../../utils/use-app-dispatch.hook';

const getResultMessage = ({
  result,
  value = '',
}: Draw['drawStatus']): string => {
  switch (result) {
    case DrawResult.Draw:
      return '🤝 Remis';
    case DrawResult.NotPlayed:
      return '❌ Rozgrywka nie rozegrana ';
    case DrawResult.Pending:
      return '⏳ Oczekiwanie na wynik';
    case DrawResult.Win:
      return `🏆 Wygrana ${value}`;
    default:
      return '❓Nieznany status';
  }
};

export const DrawHistory = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const draws = useAppSelector((state) =>
    selectFinishedDrawsByGroupId(state, id)
  );
  const drawsFetchState = useAppSelector((state) =>
    selectDrawsFetchStateByGroupId(state, id)
  );

  const handleBackButtonClick = () => {
    navigate(`/draw/${id}`);
  };

  useLayoutEffect(() => {
    if (drawsFetchState === DrawFetchState.Idle) {
      dispatch(loadDrawsAction(id));
    }
  }, [dispatch, drawsFetchState, id]);

  if (drawsFetchState !== DrawFetchState.Loaded) {
    return (
      <div>
        <h2>Ładowanie danych...</h2>
      </div>
    );
  }

  return (
    <div>
      <h2>📜 Historia Losowań</h2>
      <ul>
        {draws.map((draw) => (
          <li key={draw.id}>
            <h3>{formatDate(draw.createdAt)}</h3>
            <p>{getResultMessage(draw.drawStatus)}</p>
            <ul style={{ padding: '0' }}>
              {Object.values(draw.teams).map(({ name, participants }) => (
                <li key={name} style={{ display: 'flex' }}>
                  <h4>{name}:</h4>
                  <ul style={{ display: 'flex', padding: '0 3px 0' }}>
                    {participants.map((participant) => (
                      <li
                        style={{ display: 'block', padding: '0 0 0 3px' }}
                        key={participant.id}
                      >
                        {participant.name},{' '}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <ActionList>
        <button onClick={handleBackButtonClick}>Wróć</button>
      </ActionList>
    </div>
  );
};

const ActionList = styled.div`
  display: flex;
  flex-direction: column;
`;
