import { apiClientService } from '../utils/api-client.service';
import { Draw } from './draw.reducer';

const API_BASE_URL = '/roster-api/v1';

export class DrawService {
  static async getDraws(groupId: string): Promise<Draw[]> {
    try {
      const result = await apiClientService.get<Draw[]>(
        `${API_BASE_URL}/groups/${groupId}/draws`
      );
      return result.data;
    } catch (error) {
      console.error('Error fetching draws:', error);
      throw new Error('Failed to fetch draws');
    }
  }

  static async createDraw(
    token: string,
    groupId: string,
    teamNames: string[],
    participants: { id: string; name: string }[]
  ): Promise<Draw> {
    try {
      const result = await apiClientService.post<Draw>(
        `${API_BASE_URL}/groups/${groupId}/draws/generate`,
        {
          teamNames,
          participants,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result.data;
    } catch (error) {
      console.error('Error generating draw:', error);
      throw new Error('Failed to generate draw');
    }
  }

  static async updateDrawStatus(
    token: string,
    groupId: string,
    drawId: string,
    updatedData: Partial<Draw>
  ): Promise<Draw> {
    try {
      const result = await apiClientService.post<Draw>(
        `${API_BASE_URL}/groups/${groupId}/draws/${drawId}/status`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result.data;
    } catch (error) {
      console.error('Error updating draw:', error);
      throw new Error('Failed to update draw');
    }
  }
}
