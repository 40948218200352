import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        //margin: 0;
        //padding: 0;
        box-sizing: border-box;
    }
    
    html, body {
        height: 100%;
        margin: 0;
        padding: 0;
    }

    button {
        width: 150px;
    }

    input[type="text"] {
        width: 150px;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-top: 0.5rem; /* Zmniejszony górny margines */
        
    }
    h1 {
        margin-bottom: 0.75rem;
    }

    h2 {
        margin-bottom: 0.5rem;
    }

    h3, h4, h5, h6 {
        margin-bottom: 0.25rem;
    }

    p {
        margin-top: 0.25rem; /* Bardzo mały górny margines */
        margin-bottom: 0.5rem; /* Dolny margines wystarczająco czytelny */
    }
    ul, ol {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 1.5rem; /* Wcięcie dla lepszej czytelności */
    }
`;
