import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { Group } from '../group.reducer';
import { GroupService } from '../group.service';

export const loadGroupAction = createAsyncThunk<Group, string, ThunkConfig>(
  'group/load-group-action',
  async (id, { getState }) => {
    const token = getState().app.token;
    return await GroupService.getGroup(id, token);
  }
);
