import React, { useState } from 'react';

interface InputWithButtonProps {
  initialValue?: string;
  onButtonClick: (value: string) => void;
  placeholder?: string;
  buttonText?: string;
  buttonWidth?: string;
}

export const InputWithButton: React.FC<InputWithButtonProps> = ({
  initialValue = '',
  onButtonClick,
  placeholder = '',
  buttonText = 'Submit',
  buttonWidth = 'auto',
}) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleButtonClick = () => {
    setValue('');
    onButtonClick(value);
  };

  return (
    <div>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
      />
      <button style={{ width: buttonWidth }} onClick={handleButtonClick}>
        {buttonText}
      </button>
    </div>
  );
};
