import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store';
import { GroupService } from '../group.service';
import { defaultGroupTeams } from '../group.constans';
import { setGroup } from './base.actions';
import { AuthService } from '../../app/auth.service';
import { LocalStorageService } from '../../utils/local-storage.service';
import { setToken } from '../../app/actions/base.action';

export const createGroupAction = createAsyncThunk<
  void,
  { id: string; name: string },
  ThunkConfig
>(
  'group/create-group-action',
  async ({ id, name }, { getState, rejectWithValue, dispatch }) => {
    const newGroup = {
      id,
      name,
      teams: defaultGroupTeams,
      participants: [],
    };

    dispatch(setGroup(newGroup));

    let token = getState().app.token;

    if (!token) {
      token = await AuthService.createUser();
    }
    if (!token) {
      return rejectWithValue(null);
    }

    LocalStorageService.setItem('token', token);
    dispatch(setToken(token));

    const group = await GroupService.createGroup(token, newGroup);
    dispatch(setGroup(group));
  }
);
