import { Group } from '../../group.reducer';
import { useNavigate } from 'react-router';

export const GroupListItem = ({ name, id }: Group) => {
  const navigate = useNavigate();

  const handleGroupListItemClick = () => {
    navigate(`/draw/${id}`);
  };

  return <li onClick={handleGroupListItemClick}>{name}</li>;
};
